import '../styles/index.scss';

import '../images/kateryna-lg.jpg';
import '../images/kateryna.jpg';
import '../images/olga-lg.jpg';
import '../images/olga.jpg';
import '../images/slider-img.jpg';
import '../images/slider-img-2.jpg';
import '../images/slider-img-3.jpg';
import '../images/slider-img-4.jpg';
import '../images/slider-img-5.jpg';
import '../images/slider-img-6.jpg';
import '../images/slider-img-7.jpg';
import '../images/slider-img-8.jpg';
import '../images/slider-img-9.jpg';
import '../images/slider-img-10.jpg';
import '../images/slider-img-11.jpg';
import '../images/slider-img-12.jpg';
import '../images/slider-img-13.jpg';
import '../images/slider-img-14.png';
import '../images/slider-img-15.jpg';
import '../images/slider-img-16.jpg';

import '../images/icons/arrow-cursor-left.png';
import '../images/icons/arrow-cursor-right.png';
import '../images/icons/close.svg';
import '../images/icons/facebook.svg';
import '../images/icons/instagram.svg';

import * as THREE from '../../node_modules/three/build/three.min.js';

window.addEventListener('DOMContentLoaded', () => {

    // MODALS
    const modalLinks = document.querySelectorAll('[data-modal-link]');
    const modals = document.querySelectorAll('[data-modal]');
    const modalClose = document.querySelectorAll('.modal-close');
    const body = document.querySelector('body');
    let currentModal;

    modalLinks.forEach(element => {
        element.addEventListener('click', (e) => {
            e.preventDefault();
            modals.forEach(elem => {
                if (element.dataset.modalLink === elem.dataset.modal) {
                    elem.classList.add('is-open');
                    body.classList.add('no-scroll');
                    currentModal = elem;
                }
            });
            currentModal.addEventListener('click', () => {
                currentModal.classList.remove('is-open');
                body.classList.remove('no-scroll');
            });
        });
    });

    modalClose.forEach(close => {
        close.addEventListener('click', () => {
            currentModal.classList.remove('is-open');
            body.classList.remove('no-scroll');
        });
    });

    // SLIDER
    let i = 0;
    let slides = [];
    let sliderItems = document.querySelectorAll('.slider-item');
    let prevSlide = document.querySelector('.slider-prev');
    let nextSlide = document.querySelector('.slider-next');

    sliderItems.forEach(slide => {
        slides.push(slide);
    });

    prevSlide.addEventListener('click', () => {
        slides[i].classList.remove('is-open');
        i--;
        if (i < 0) i = slides.length - 1;
        slides[i].classList.add('is-open');
    });

    nextSlide.addEventListener('click', () => {
        slides[i].classList.remove('is-open');
        i++;
        if (i === slides.length) i = 0;
        slides[i].classList.add('is-open');
    });

    // SCROLLSPY
    const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            const id = entry.target.getAttribute('id');
            const elem = document.querySelector(`ul li a[href="#${id}"]`);
            entry.intersectionRatio > 0 ? elem.classList.add('is-active') : elem.classList.remove('is-active');
        });
    });

    document.querySelectorAll('.section').forEach((section) => {
        observer.observe(section);
    });

    // three.js 3D-model
    let geometry = new THREE.CylinderGeometry(0.6 / Math.sqrt(2), 1 / Math.sqrt(2), 1.8, 4, 6);
    geometry.rotateZ(1.5).rotateX(.83).rotateY(-1);

    let edges = new THREE.EdgesGeometry(geometry);

    let trapezoid;
    if (window.innerWidth < 768) {
        trapezoid = new THREE.LineSegments(edges, new THREE.LineBasicMaterial({ color: 0xffffff }));
    } else {
        trapezoid = new THREE.LineSegments(edges, new THREE.LineBasicMaterial({ color: 0x000000 }));
    }

    let scene = new THREE.Scene();
    scene.add(trapezoid);

    let camera = new THREE.PerspectiveCamera(20, window.innerWidth / window.innerHeight, 0.1, 2000);
    let renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setPixelRatio(window.devicePixelRatio ? window.devicePixelRatio : 1);
    renderer.setClearColor(0x000000, 0);
    document.querySelector('.canvas-wrapper').appendChild(renderer.domElement);

    camera.position.z = 5;

    let mouseX = 0, mouseY = 0;
    let windowHalfX = window.innerWidth / 2;
    let windowHalfY = window.innerHeight / 2;

    function onWindowResize() {
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(window.innerWidth, window.innerHeight);
    }
    window.addEventListener('resize', onWindowResize, false);

    function onDocumentMouseMove(event) {
        mouseX = (event.clientX - windowHalfX);
        mouseY = (event.clientY - windowHalfY);
    }

    let rotationSpeed = 0.00001;

    function render() {
        camera.lookAt(scene.position);
        renderer.render(scene, camera);
    }

    let animate = function () {
        requestAnimationFrame(animate);
        trapezoid.rotation.x -= (mouseY - camera.position.x) * rotationSpeed / 4;
        trapezoid.rotation.y -= (mouseX - camera.position.y) * rotationSpeed / 4;
        render();
    };

    animate();
    document.addEventListener('mousemove', onDocumentMouseMove, false);

    // hide trapezoid on header scrolled
    const header = document.querySelector('.header');
    const canvas = document.querySelector('.canvas-wrapper');
    window.addEventListener('scroll', () => {
        if (header.scrollHeight <= window.scrollY) {
            canvas.style = "opacity: 0; z-index: -1;";
            rotationSpeed = 0;
        } else {
            canvas.style = "opacity: 1; z-index: 0";
            rotationSpeed = 0.00001;
        }
    });
});